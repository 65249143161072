import { Button, Icon, Image, VStack } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { OuraTextLogo, TEXT, useGradient } from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { LaunchpadActionView } from '../../launchpad-action-view';

export function SizingKitIntro({
  wearableProvider,
  onClose,
}: {
  wearableProvider: WearableProviders;
  onClose?: () => void;
}) {
  const swiper = useSwiper();
  const gradient = useGradient('blue_white');

  const strivewareHero = (
    <VStack spacing={8}>
      <TEXT.H1 color={'neutral.white'}>StriveWare</TEXT.H1>
      <Image
        src={'./images/striveware/striveware-sizing-box.webp'}
        alt={'Oura Ring'}
        w={'265px'}
      />
    </VStack>
  );

  const ouraHero = (
    <VStack spacing={8}>
      <Icon as={OuraTextLogo} h={'auto'} w={36} />
      <Image
        src={'./images/oura/oura-ring.webp'}
        alt={'Oura Ring'}
        w={'175px'}
      />
    </VStack>
  );

  const strivewareTextEntries = (
    <VStack spacing={4}>
      <TEXT.H2>Request a Sizing Kit Now</TEXT.H2>
      <TEXT.P1 color={'neutral.400'}>
        Discover the power of Strive&apos;s cutting-edge sensor, unlocking
        valuable physiological data. Simply share your shipping address to
        receive a sizing kit and embark on your journey to enhanced well-being.
      </TEXT.P1>
    </VStack>
  );

  const ouraTextEntries = (
    <VStack spacing={4}>
      <TEXT.H2>Elevate Learning with Oura</TEXT.H2>
      <TEXT.P1 color={'neutral.400'}>
        Strive, in partnership with Oura, will setup a cutting-edge sensor to
        unlock your physiological data. Enjoy a world-class experience with
        top-notch data security. Get ready to dive into a new realm of
        well-being!
      </TEXT.P1>
    </VStack>
  );

  return (
    <LaunchpadActionView
      gradient={gradient}
      onBack={() => {
        wearableProvider === 'striveware'
          ? onClose && onClose()
          : swiper && swiper.slidePrev();
      }}
      hero={wearableProvider === 'striveware' ? strivewareHero : ouraHero}
      textBox={
        wearableProvider === 'striveware'
          ? strivewareTextEntries
          : ouraTextEntries
      }
      footer={
        <Button
          variant={'primary'}
          onClick={() => swiper.slideNext()}
          w={'full'}
        >
          Continue
        </Button>
      }
    />
  );
}
