import { Button, Flex, Icon, Image, Link } from '@chakra-ui/react';

import {
  AlertBox,
  ArenaLogo,
  OuraTextLogo,
  TEXT,
  useAppStateChange,
  useGradient,
} from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';

import { LaunchpadActionText } from './launchpad-action-text';
import { LaunchpadActionView } from './launchpad-action-view';
import { useUpdateLaunchpadChecklist } from './use-update-launchpad-checklist';

type LaunchpadAuthorizeDataSyncProps = {
  onClose: () => void;
  onBack?: () => void;
};

export function LaunchpadAuthorizeDataSync({
  onClose,
  onBack = onClose,
}: LaunchpadAuthorizeDataSyncProps) {
  const { data: user, invalidate } = useUserProfile();

  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });

  const statusIsValid = user?.profile?.wearable[0]?.status === 'valid';
  const gradient = useGradient('blue_white');
  const authURL = user?.profile?.wearable[0]?.url;

  useAppStateChange((isActive) => {
    if (isActive) invalidate();
  });

  const actionViewProps = statusIsValid
    ? {
        hero: (
          <Image
            mx={'auto'}
            src={'./images/launchpad/check-blue-bubble.webp'}
            alt={'You are now synced!'}
            w={'240px'}
          />
        ),
        textBox: (
          <LaunchpadActionText
            boldText="Data Authorization Successful!"
            bodyText={`Congratulations! Your Oura Ring data is now connected with Strive. Let's begin the journey begin!`}
          />
        ),
        footer: (
          <>
            {updateChecklist.isError && (
              <AlertBox
                mb={4}
                status={'error'}
                title={'Oops! An Error Occurred'}
                description={
                  'We were unable to update your checklist, please try again or contact Strive Support.'
                }
              />
            )}
            <Button
              isLoading={updateChecklist.isLoading}
              w={'full'}
              onClick={() =>
                updateChecklist.mutate({
                  slug: 'sync_wearable',
                  status: 'completed',
                })
              }
              variant={'primary'}
            >
              Done
            </Button>
          </>
        ),
      }
    : {
        hero: (
          <Flex direction={'column'} align={'center'}>
            <Flex gap={4} alignItems={'center'} mb={8}>
              <Icon as={ArenaLogo} boxSize={50} />
              <TEXT.P1_BOLD fontFamily={'brand'}>ARENA STRIVE</TEXT.P1_BOLD>
            </Flex>
            <Image
              src={'./images/launchpad/data-transfer.png'}
              alt="Sync Data"
              w={10}
            />
            <Icon as={OuraTextLogo} boxSize={'120px'} />
          </Flex>
        ),
        textBox: (
          <LaunchpadActionText
            boldText={`Let's connect your Oura Ring data with Strive`}
            bodyText={` Click the link below to authorize data access and start your
          Strive journey.`}
          />
        ),
        footer: authURL && (
          <Link variant={'button'} w={'full'} href={authURL} target={'_blank'}>
            Connect to Oura
          </Link>
        ),
      };

  return (
    <LaunchpadActionView
      {...actionViewProps}
      onBack={statusIsValid ? onBack : undefined}
      gradient={gradient}
    />
  );
}
