import { useState } from 'react';
import { Flex, Modal, ModalBody, ModalContent } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AlertBox, useModalDisclosure } from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { LaunchpadConnectWearable } from '../launchpad-connect-wearable';
import { useUpdateLaunchpadChecklist } from '../use-update-launchpad-checklist';
import { DownloadPartner } from './launchpad-download-partner';
import { SelectDeliveryOutcome } from './select-delivery-outcome';
import { WearableNotDelivered } from './wearable-not-delivered';

RingDeliveryConfirmation.useDisclosure = function () {
  return useModalDisclosure('Ring Delivery Confirmation');
};

export function RingDeliveryConfirmation({
  isOpen,
  onClose,
  wearableProvider,
}: {
  isOpen: boolean;
  onClose: () => void;
  wearableProvider: WearableProviders;
}) {
  const [view, setView] = useState<'ConnectWearable' | 'Download' | 'Wait'>();

  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });

  const handleClose = () => {
    if (view === 'Wait') {
      onClose();
      return;
    }

    updateChecklist.mutate({
      slug: 'confirm_ring_delivery',
      status: 'completed',
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalContent m={0} bgGradient={'none'}>
        <ModalBody m={0} p={0} bgGradient={'none'}>
          <Flex h={'full'} direction={'row'}>
            <Swiper allowTouchMove={false}>
              <SwiperSlide>
                <SelectDeliveryOutcome
                  onClose={handleClose}
                  setDeliveryOutcome={setView}
                  canProceed={Boolean(view)}
                  error={
                    updateChecklist.isError && (
                      <AlertBox
                        status={'error'}
                        title={'Oops!'}
                        description="We are unable to update the Launchpad Checklist right now, please try again!"
                      />
                    )
                  }
                  wearableProvider={wearableProvider}
                />
              </SwiperSlide>
              <SwiperSlide>
                {view === 'ConnectWearable' ? (
                  <LaunchpadConnectWearable
                    wearableProvider={wearableProvider}
                    onClose={handleClose}
                  />
                ) : view === 'Download' ? (
                  <DownloadPartner onClose={handleClose} />
                ) : (
                  <WearableNotDelivered onClose={onClose} />
                )}
              </SwiperSlide>
            </Swiper>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
