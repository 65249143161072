import { Button, Flex } from '@chakra-ui/react';

import { ExploreIcon, Icon, TEXT } from '@arena-labs/strive2-ui';

import { ExplorationsActionList } from './explorations-action-list';

export function ExplorationsWelcome({
  onContinue,
}: {
  onContinue?: () => void;
}) {
  return (
    <Flex direction="column" width="full" h="full">
      <Flex
        direction="column"
        width="full"
        h="full"
        textAlign="center"
        justifyContent={'space-evenly'}
      >
        <TEXT.H1 as={Flex} flexDirection={'column'}>
          <span> Welcome To</span>
          <span>EXPLORATIONS</span>
        </TEXT.H1>

        <Icon as={ExploreIcon} size="80px" color="logo" />

        <TEXT.H3 color="neutral.200">
          A Post-Foundation Journey Tailored Just for You.
        </TEXT.H3>

        <ExplorationsActionList />
      </Flex>
      <Button
        variant="primary"
        w="full"
        alignSelf={'flex-end'}
        onClick={onContinue}
      >
        Continue
      </Button>
    </Flex>
  );
}
