import { Button, Flex, Image } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { TEXT, useGradient } from '@arena-labs/strive2-ui';

import { LaunchpadActionView } from '../launchpad-action-view';

export function WearableQuestionnaireIntro({
  onBack,
  wearable,
}: {
  wearable: string;
  onBack: () => void;
}) {
  const gradient = useGradient('blue_white');
  const swiper = useSwiper();
  return (
    <LaunchpadActionView
      onBack={onBack}
      gradient={gradient}
      hero={
        <Image
          src={'./images/launchpad/med-cards-bubble.webp'}
          alt={''}
          maxW={'300px'}
        />
      }
      textBox={
        <Flex direction={'column'} gap={4}>
          <TEXT.H2>Let&apos;s Setup your Wearable</TEXT.H2>
          <TEXT.P1_SEMIBOLD color={'neutral.400'}>
            Answer a few questions to kickstart your{' '}
            {wearable === 'oura'
              ? `Oura Ring setup and get the
              perfect size!`
              : `device set-up!`}
          </TEXT.P1_SEMIBOLD>
        </Flex>
      }
      footer={
        <Button variant={'primary'} w="full" onClick={() => swiper.slideNext()}>
          Get Started
        </Button>
      }
    />
  );
}
