import { Capacitor } from '@capacitor/core';
import { Image, Link } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { useGradient } from '@arena-labs/strive2-ui';

import { LaunchpadActionText } from '../launchpad-action-text';
import { LaunchpadActionView } from '../launchpad-action-view';
import { useUpdateLaunchpadChecklist } from '../use-update-launchpad-checklist';

export function DownloadPartner({ onClose }: { onClose: () => void }) {
  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });
  const gradient = useGradient('black_white');
  const platform = Capacitor.getPlatform();
  const swiper = useSwiper();

  return (
    <LaunchpadActionView
      gradient={gradient}
      onBack={() => swiper.slidePrev()}
      hero={
        <Image
          mx={'auto'}
          src={'./images/launchpad/oura-app-icon.webp'}
          alt={'Oura App Homescreen Icon'}
          w={'160px'}
          mb={8}
        />
      }
      textBox={
        <LaunchpadActionText
          boldText={`Download Oura App Now`}
          bodyText={` No worries! Once you download the Oura app, you can easily set up
        your ring. After authorization, you'll soon be able to unlock
        your data view on Strive.`}
        />
      }
      footer={
        <Link
          variant={'button'}
          w={'full'}
          href={
            platform === 'ios'
              ? 'https://apps.apple.com/us/app/oura/id1043837948'
              : 'https://play.google.com/store/apps/details?id=com.ouraring.oura'
          }
          target={'_blank'}
          onClick={() => {
            updateChecklist.mutate({
              slug: 'confirm_ring_delivery',
              status: 'completed',
            });
          }}
        >
          Download Oura App
        </Link>
      }
    />
  );
}
