import { useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { formatDuration, intervalToDuration, isBefore } from 'date-fns';

import { roundDuration, useGradient } from '@arena-labs/strive2-ui';

export type LaunchpadCountdownProps = {
  startDate: Date;
};

export function LaunchpadCountdown({ startDate }: LaunchpadCountdownProps) {
  const timeRemaining = useMemo(() => {
    if (isBefore(startDate, new Date())) return '00:00:00';

    const diff = intervalToDuration({ start: new Date(), end: startDate });
    const duration = roundDuration(diff);
    return formatDuration(duration);
  }, [startDate]);

  const borderBg = useGradient('secondary.heavy');
  return (
    <Flex
      direction="column"
      gap="3"
      align="center"
      layerStyle="1dp"
      position="relative"
      borderRadius="base"
      padding="4"
      _before={{
        content: '""',
        position: 'absolute',
        inset: '-2px',
        borderRadius: 'inherit',
        background: borderBg,
        zIndex: -1,
      }}
    >
      <Text textStyle="p1_semibold" color="white">
        {timeRemaining}
      </Text>
      <Text textStyle="p2">until Strive begins</Text>
    </Flex>
  );
}
