import { Button, Center, Image, Spacer, useMediaQuery } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { PageStack, TEXT } from '@arena-labs/strive2-ui';

import { OnboardRewardSlideProps } from './incentive-types';

export default function IncentiveHubIntro({
  primaryAction,
}: OnboardRewardSlideProps) {
  const swiper = useSwiper();
  const [isCompact] = useMediaQuery('(max-height: 750px)');

  return (
    <PageStack
      h="full"
      pb="6"
      pt="10"
      spacing="4"
      align="stretch"
      gridArea="content-body"
      overflowY="auto"
      textAlign={'center'}
    >
      <Center w="full">
        <Image
          src={'/images/incentives/reward-cup.gif'}
          alt={'A Reward Trophy Icon'}
          w={isCompact ? 250 : 300}
        />
      </Center>
      <TEXT.H1 fontSize={isCompact ? 'h2' : 'h1'}>
        Welcome to <br /> Arena Rewards Hub
      </TEXT.H1>
      <TEXT.H2 fontSize={isCompact ? 'h3' : 'h2'} px={4}>
        Stay on track by leveraging the power of energy and motivation that
        comes from a sense of achievement!
      </TEXT.H2>
      <Spacer />
      <Button
        variant="primary"
        onClick={primaryAction ? primaryAction : () => swiper.slideNext()}
      >
        Introduce Me!
      </Button>
    </PageStack>
  );
}
