import { useMemo, useState } from 'react';
import Head from 'next/head';
import { Box, Fade } from '@chakra-ui/react';

import { OnboardingTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { UserProfile } from '@arena-labs/shared-models';
import { useHomepage } from '@arena-labs/strive2-coaching';
import { AppContainer } from '@arena-labs/strive2-ui';
import { $API, IncentiveType, useUserProfile } from '@strive/api';

import { useCurrentIncentive } from '../incentives/use-current-incentive';
import { OnboardIncentive } from '../onboarding/incentives/onboard-incentive';
import { Welcome } from '../onboarding/onboard-welcome';

type OnboardingState = keyof typeof STEPS;

type OnboardStepProps = {
  user?: UserProfile;
  incentive?: IncentiveType;
  onProceed: () => void;
  onNavigateBack: () => void;
  isLoading: boolean;
};

type OnboardingStep = {
  isAvailable: (props: Partial<OnboardStepProps>) => boolean;
  render: (props: OnboardStepProps) => JSX.Element | undefined;
};

const STEPS = {
  welcome: {
    isAvailable: () => true,
    render: ({ user, onProceed, isLoading }) => (
      <Welcome
        isLoading={isLoading}
        userName={user?.first_name}
        onProceed={onProceed}
      />
    ),
  },
  incentive: {
    isAvailable: ({ incentive }) => Boolean(incentive),
    render: ({ incentive, onProceed, onNavigateBack }) =>
      incentive && (
        <OnboardIncentive
          incentive={incentive}
          onProceed={onProceed}
          onNavigateBack={onNavigateBack}
        />
      ),
  },
} satisfies Record<string, OnboardingStep>;

/**
 * Returns the first step that is available.
 * @param props The props passed to the OnboardStep component.
 * @param startIndex The index of the first step to check. Defaults to 0.
 * @returns The id of the first step that is available.
 */
function getFirstStep(props: Partial<OnboardStepProps>, startIndex = 0) {
  const steps = Object.entries(STEPS).slice(startIndex);
  const first = steps.find(([id, step]) => step.isAvailable(props))?.[0];
  if (first) {
    return first as OnboardingState;
  }
}

export function OnboardingHome() {
  const [, { refresh }] = useHomepage();
  const { data: user } = useUserProfile();
  const { data: incentive } = useCurrentIncentive();
  const [onboardState, setOnboardState] = useState<OnboardingState>('welcome');
  const setLearningState = $API.useSetLearningState({}, { onSettled: refresh });

  const nextStep = useMemo(() => {
    const currentIdx = Object.keys(STEPS).indexOf(onboardState);
    const nextStep = getFirstStep({ user, incentive }, currentIdx + 1);
    return nextStep;
  }, [onboardState, user, incentive]);

  const analytics = useAnalytics();

  const onNavigateBack = () => {
    const currentIdx = Object.keys(STEPS).indexOf(onboardState);
    const lastStep = getFirstStep({ user }, currentIdx - 1);
    if (lastStep) {
      setOnboardState(lastStep);
    }
  };

  const onProceed = () => {
    if (nextStep) {
      setOnboardState(nextStep);
      analytics.logEvent(OnboardingTrackingEvent.OnboardingTransition, {
        from: onboardState,
        to: nextStep,
      });
    } else {
      setLearningState.mutate({ learning_state: 'in_progress' });
      analytics.logEvent(OnboardingTrackingEvent.OnboardingFinished, {
        from: onboardState,
      });
    }
  };

  const isLoading = setLearningState.isLoading;

  return (
    <AppContainer h="full" data-learning-state="onboarding">
      <Head>
        <title>Arena Strive | Onboarding</title>
      </Head>
      <Box h="full" data-onboard-state={onboardState}>
        <Fade
          in
          key={onboardState}
          transition={{ enter: { duration: 1 } }}
          style={{ height: '100%' }}
        >
          {STEPS[onboardState].render({
            isLoading,
            user,
            onProceed,
            onNavigateBack,
            incentive,
          })}
        </Fade>
      </Box>
    </AppContainer>
  );
}
