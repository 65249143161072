import React from 'react';
import { Flex } from '@chakra-ui/react';

import { Program, ProgramUpNextCard } from '@arena-labs/strive2-content';
import { TEXT } from '@arena-labs/strive2-ui';

import { getProgramPage, getVideoPage } from '../../lib/utils';

export type ProgramWidgetProps = {
  program: Program;
};

export function ProgramWidget({ program }: ProgramWidgetProps) {
  return (
    <Flex gap={2} direction="column" w="full">
      <TEXT.P2 color="neutral.500"> Program In-Progress</TEXT.P2>
      <ProgramUpNextCard
        program={program}
        href={getProgramPage(program)}
        video={program.next_video}
        getVideoPage={(v) => getVideoPage(v, { program: program.slug })}
        headingLevel="h3"
      />
    </Flex>
  );
}
