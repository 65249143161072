import { Button, Image } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { useGradient } from '@arena-labs/strive2-ui';

import { LaunchpadActionText } from '../launchpad-action-text';
import { LaunchpadActionView } from '../launchpad-action-view';

export function WearableNotDelivered({ onClose }: { onClose: () => void }) {
  const gradient = useGradient('black_white');
  const swiper = useSwiper();

  return (
    <LaunchpadActionView
      gradient={gradient}
      onBack={() => swiper.slidePrev()}
      hero={
        <Image
          mx={'auto'}
          src={'./images/launchpad/delivery-drone.webp'}
          alt={'Delivery Drone'}
          w={'240px'}
        />
      }
      textBox={
        <LaunchpadActionText
          boldText={`Arriving Shortly`}
          bodyText={`No problem! Keep an eye on your mailbox. As soon as your ring
        arrives, follow the setup instructions in the package. Come back to
        Strive once your ring is set up!`}
        />
      }
      footer={
        <Button onClick={() => onClose()} variant={'primary'} w={'full'}>
          Home
        </Button>
      }
    />
  );
}
