import { Flex } from '@chakra-ui/react';

import { TEXT } from '@arena-labs/strive2-ui';

export function LaunchpadActionText({
  boldText,
  bodyText,
}: {
  boldText: string;
  bodyText: string;
}) {
  return (
    <Flex direction={'column'} gap={4}>
      <TEXT.H2 color={'neutral.white'}>{boldText}</TEXT.H2>
      <TEXT.P1 color={'neutral.300'}>{bodyText}</TEXT.P1>
    </Flex>
  );
}
