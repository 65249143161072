import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  Grid,
  Icon,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react';
import { match, P } from 'ts-pattern';

import {
  CheckedCircleIcon,
  CircleIcon,
  PartnerDeviceIcon,
  WearableIcon,
} from '@arena-labs/strive2-ui';

import { AuthorizeWearableModal } from './authorize-wearable/authorize-wearable-modal';
import { ChecklistItem } from './checklist-item';
import { RingDeliveryConfirmation } from './ring-delivery-confirmation/ring-delivery-confirmation';
import { RingSizeSelection } from './ring-sizing/ring-size-selection';
import {
  LaunchpadChecklistItems,
  LaunchpadWearable,
  WearableChecklistItems,
} from './types';
import { WearableQuestionnaire } from './wearable-questionnaire/wearable-questionnaire';

export type WearableChecklistProps = {
  wearable: NonNullable<LaunchpadWearable>;
  list: NonNullable<WearableChecklistItems>;
  status: NonNullable<LaunchpadChecklistItems[number]>['status'];
  onCompleteItem?: () => void;
};

export function WearableChecklist({
  wearable,
  list,
  onCompleteItem,
  status,
}: WearableChecklistProps) {
  const wearableOrder = WearableQuestionnaire.useDisclosure();
  const ringSizing = RingSizeSelection.useDisclosure();
  const ringDelivery = RingDeliveryConfirmation.useDisclosure();
  const authorizeData = AuthorizeWearableModal.useDisclosure();

  const lockedStyles = {
    item: { color: 'neutral.700' },
    icon: {},
  };
  const activeStyles = {
    item: {},
    icon: { color: 'secondary.400' },
  };
  const isLocked = (status: (typeof list)[number]['status']) =>
    status === 'locked' || status === 'skipped';

  const firstActive = list.find((item) => item.status === 'active');

  const title = `My ${
    wearable.provider === 'striveware' ? 'StriveWare' : 'Wearable'
  } Setup`;

  if (status === 'completed') {
    return <ChecklistItem icon={WearableIcon} label={title} status={status} />;
  }

  const wearableSetUpView: Record<
    | 'wearable_order'
    | 'add_wearable_size'
    | 'confirm_ring_delivery'
    | 'sync_wearable',
    {
      view: React.ReactNode;
      disclosure: UseDisclosureReturn;
    }
  > = {
    wearable_order: {
      view: (
        <WearableQuestionnaire
          wearableProvider={wearable.provider}
          {...wearableOrder}
        />
      ),
      disclosure: wearableOrder,
    },
    add_wearable_size: {
      view: (
        <RingSizeSelection
          {...ringSizing}
          wearableProvider={wearable.provider}
        />
      ),
      disclosure: ringSizing,
    },
    confirm_ring_delivery: {
      view: (
        <RingDeliveryConfirmation
          {...ringDelivery}
          wearableProvider={wearable.provider}
        />
      ),
      disclosure: ringDelivery,
    },
    sync_wearable: {
      view: (
        <AuthorizeWearableModal
          wearableProvider={wearable.provider}
          {...authorizeData}
        />
      ),
      disclosure: authorizeData,
    },
  };

  const listLabels = {
    wearable_order:
      wearable.provider === 'striveware'
        ? 'Submit Address for a Sizing Kit'
        : 'Wearable Questionnaire',
    add_wearable_size:
      wearable.provider === 'striveware'
        ? 'Submit StriveWare Size and Color'
        : 'Submit Oura Ring Size',
    confirm_ring_delivery:
      wearable.provider === 'striveware'
        ? `Confirm StriveWare Delivery`
        : 'Confirm Oura Ring Delivery',
    sync_wearable:
      wearable.provider === 'striveware'
        ? 'Pair My StriveWare'
        : 'Authorize Data Access for Strive',
  };

  return (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem
        layerStyle="1dp"
        borderRadius="base"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="neutral.900"
        py="6"
        px="4"
      >
        <h2>
          <AccordionButton p="0" flexDirection="column">
            <Grid
              w="full"
              templateColumns="auto 1fr auto"
              columnGap="2"
              rowGap="1"
              textAlign="left"
              alignItems="center"
            >
              {wearable.provider === 'striveware' ? (
                <Image
                  src={'./images/striveware/black-ring-grey-light.webp'}
                  w={7}
                  alt={'Biometric Ring'}
                />
              ) : (
                <Icon as={PartnerDeviceIcon} boxSize={7} color="icon.primary" />
              )}

              <Text textStyle="p1_semibold" color="white">
                {title}
              </Text>
              <AccordionIcon color="neutral.400" />
              <Text
                gridColumnStart="2"
                textStyle="p3"
                color={
                  wearable.status === 'complete'
                    ? 'success.300'
                    : wearable.status === 'not_ordered'
                    ? 'error.300'
                    : 'neutral.400'
                }
              >
                {match(wearable)
                  .with({ status: 'complete' }, () => 'Completed')
                  .with({ status: 'delivered' }, () => 'Delivered')
                  .with(
                    { status: 'shipped', tracking_url: P.string },
                    ({ tracking_url }) => (
                      <>
                        Track My Ring:{' '}
                        <Link
                          variant="underline"
                          href={tracking_url}
                          target="_blank"
                          rel="noopener noreferer"
                          onClick={
                            // Prevent the accordion from toggling when clicking the link
                            (e) => e.stopPropagation()
                          }
                        >
                          {new URL(tracking_url).hostname}
                        </Link>
                      </>
                    ),
                  )
                  .otherwise(() => 'Not Completed')}
              </Text>
            </Grid>
          </AccordionButton>
        </h2>
        <AccordionPanel
          p="0"
          mt="4"
          display="flex"
          flexDirection="column"
          gap="4"
        >
          <Divider mb={2} opacity={0.1} />
          <List spacing="2">
            {list.map((item) => (
              <ListItem
                key={item.slug}
                textStyle="p2"
                {...(isLocked(item.status) ? lockedStyles : activeStyles).item}
              >
                <ListIcon
                  as={
                    item.status === 'completed' ? CheckedCircleIcon : CircleIcon
                  }
                  {...(isLocked(item.status) ? lockedStyles : activeStyles)
                    .icon}
                />
                {listLabels[item.slug]}
              </ListItem>
            ))}
          </List>

          {firstActive ? (
            <>
              <Button
                variant="primary"
                onClick={() => {
                  wearableSetUpView[firstActive.slug].disclosure.onOpen();
                }}
              >
                {firstActive.label}
              </Button>
              {wearableSetUpView?.[firstActive.slug]?.view}
            </>
          ) : null}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
