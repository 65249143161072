import {
  CoachMinimumDetail,
  Video,
  VideoPageMetadata,
} from '@arena-labs/shared-models';
import { CoachingSectionType } from '@arena-labs/strive2-coaching';
import { ContentSectionType, Program } from '@arena-labs/strive2-content';
import { DataSectionType } from '@arena-labs/strive2-data';

export function getHomePage() {
  return '/';
}

export function getCoachingSections(): {
  [Type in CoachingSectionType]: string;
} {
  return {
    PROTOCOLS: '/coaching/protocols',
    SYLLABUS: '/coaching/syllabus',
    AMBASSADORS: '/coaching/ambassadors',
  };
}

export function getContentLibrarySections(): {
  [Type in ContentSectionType]: string;
} {
  return {
    VIDEOS: '/content/videos',
    SAVED: '/content/favorites',
    WATCHED: '/content/watched',
  };
}

export function getDataTabSections(): { [Type in DataSectionType]: string } {
  return {
    SNAPSHOT: '/data/snapshot',
  };
}

/**
 * Get the URL for the content library
 */
export function getContentLibraryPage(): string {
  return `/content`;
}

/**
 * Get the video detail URL for a video
 */
export function getVideoPage(
  video: Video | string,
  meta?: VideoPageMetadata,
): string {
  const videoSlug = typeof video === 'string' ? video : video.slug;
  const params = new URLSearchParams();
  if (meta?.program) {
    params.append('program', meta.program);
  }
  const queryString = params.toString() ? `?${params.toString()}` : '';
  return `/content/videos/${videoSlug}${queryString}`;
}

/**
 * Get the program detail URL for a program
 */
export function getProgramPage(program: Program): string {
  return `/coaching/programs/${program.slug}`;
}

/**
 * Get the coach detail URL
 */
export function getCoachPage(coach: Pick<CoachMinimumDetail, 'slug'>): string {
  return `/coaching/ambassadors/${coach.slug}`;
}
