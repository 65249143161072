import { TEXT } from '@arena-labs/strive2-ui';

import { WearableButton } from '../../wearable/wearable-button';
import { AppBanner, AppBannerProps } from '../app-banner';
import { MenuButton } from '../menu/menu-button';

export function HomeBanner({
  title,
  ...props
}: { title: string } & Omit<AppBannerProps, 'children'>) {
  return (
    <AppBanner sticky {...props}>
      <MenuButton />

      <TEXT.H1 fontSize={'h3'} ml={3} mr={'auto'} color={'logo'}>
        {title}
      </TEXT.H1>

      <WearableButton />
    </AppBanner>
  );
}
