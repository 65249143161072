import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';

import {
  CalendarIcon,
  ChatIcon,
  ChecklistIcon,
  ClockIcon,
  Icon,
  MotionBox,
} from '@arena-labs/strive2-ui';

export type LaunchpadWelcomeProps = {
  onEnter: () => void;
};

export function LaunchpadWelcome({ onEnter }: LaunchpadWelcomeProps) {
  return (
    <Box
      h="100vh"
      position="relative"
      bgImage="/images/launchpad/welcome.webp"
      bgSize="cover"
    >
      <Box h="full" bg="#1E2425EB">
        <Flex
          zIndex="1000"
          direction="column"
          h="full"
          px="6"
          pt="calc(24px + env(safe-area-inset-top))"
          pb="calc(24px + env(safe-area-inset-bottom))"
          gap="12"
        >
          <MotionBox
            mt="25vh"
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: 0,
            }}
          >
            <Text as="h2" textStyle="h2" color="white">
              Welcome to Strive Launchpad
            </Text>
            <Text textStyle="p2" color="neutral.400" mt="4">
              Get everything ready for a seamless learning experience.
            </Text>
          </MotionBox>

          <MotionBox
            bg="primaryAlpha.10"
            borderRadius="base"
            display="flex"
            flexDirection="column"
            gap="4"
            p="4"
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: 2,
            }}
          >
            <Text textStyle="p1_semibold">What Awaits You?</Text>
            <Grid templateColumns="auto 1fr" color="neutral.200" gap="4">
              <Icon as={ChecklistIcon} color="primary.500" />
              <Text textStyle="p2">A Guided Onboarding Checklist</Text>

              <Icon as={ChatIcon} color="primary.500" />
              <Text textStyle="p2">Personalized Onboarding Support</Text>

              <Icon as={ClockIcon} color="primary.500" />
              <Text textStyle="p2">
                Countdown to Your Strive Learning Journey
              </Text>

              <Icon as={CalendarIcon} color="primary.500" />
              <Text textStyle="p2">In-Person Launch Event Details</Text>
            </Grid>
          </MotionBox>
          <MotionBox
            animate={{ opacity: [0, 1] }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: 4,
            }}
            mt="auto"
            w="full"
          >
            <Button variant="primary" onClick={onEnter} w="full">
              Enter Launchpad
            </Button>
          </MotionBox>
        </Flex>
      </Box>
    </Box>
  );
}
