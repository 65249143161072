import { Flex, List, ListIcon, ListItem } from '@chakra-ui/react';

import { CheckedBoxIcon, TEXT } from '@arena-labs/strive2-ui';

const dailyActions = [
  'Daily Arena Check-In',
  'Ongoing Access to Data Insights',
  'Join a Self-Guided Program',
];

export function ExplorationsActionList() {
  return (
    <Flex
      direction="column"
      gap="4"
      bg="primaryAlpha.10"
      px="4"
      py="3"
      borderRadius="card"
      w="full"
      textAlign="start"
    >
      <TEXT.P1_SEMIBOLD>Suggested Daily Actions:</TEXT.P1_SEMIBOLD>
      <List fontSize="p3" color="neutral.200" spacing="2">
        {dailyActions.map((action, idx) => (
          <ListItem key={idx} verticalAlign="center" pb="1">
            <ListIcon as={CheckedBoxIcon} boxSize="3" color="primary.700" />
            <TEXT.P2 pl="1" as="span">
              {action}
            </TEXT.P2>
          </ListItem>
        ))}
      </List>
    </Flex>
  );
}
