import { BoxProps, Divider, Flex, Text } from '@chakra-ui/react';

export type SectionHeaderProps = {
  label: string;
} & BoxProps;

export function SectionHeader({ label, ...rest }: SectionHeaderProps) {
  return (
    <Flex w="full" align="center" gap="4" {...rest}>
      <Text as="h2" textStyle="h2" color="white" sx={{ textWrap: 'nowrap' }}>
        {label}
      </Text>
      <Divider borderColor="neutral.900" />
    </Flex>
  );
}
