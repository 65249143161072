import 'swiper/css';
import 'swiper/css/a11y';

import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { A11y } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { WaitForQuery } from '@arena-labs/strive2-ui';

import { PracticeSetCard } from './practice-set-card';
import { useAvailablePracticeSets } from './use-available-practice-sets';

export type PracticeSetCarouselProps = {
  count?: number;
};

export function PracticeSetCarousel({ count = 3 }: PracticeSetCarouselProps) {
  const practiceSetQuery = useAvailablePracticeSets(count);

  return (
    <Flex direction="column" gap="4" h="full">
      <Text
        as="h1"
        textStyle="h3"
        color="secondary.400"
        py="4"
        textAlign="center"
      >
        Select Next Practice Set
      </Text>
      <WaitForQuery query={practiceSetQuery}>
        {([pillars, totalPillars]) => {
          if (pillars.length === 0) {
            return (
              <PracticeSetCard
                type="explorations"
                isActive
                completedFoundations
                tag={
                  <Flex gap="2" align="center">
                    <Image
                      src="/images/learning-journey/finish-flag-icon.webp"
                      alt=""
                      boxSize={12}
                      marginRight="-6"
                      zIndex="1"
                    />
                    <Text
                      fontFamily="secondary"
                      textStyle="h3"
                      bg="neutral.900"
                      py="1"
                      px="3"
                      borderRadius="base"
                    >
                      Foundations Complete!
                    </Text>
                  </Flex>
                }
              />
            );
          }

          return (
            <Box h="full" m="-8" p="8" overflow="hidden">
              <Swiper
                slidesPerView={1.001}
                spaceBetween={12}
                style={{ width: '100%', height: '100%', overflow: 'visible' }}
                modules={[A11y]}
                role="region"
                a11y={{ containerMessage: 'Available choices' }}
              >
                <VisuallyHidden slot="container-start">
                  <A11ySwiperNavigation />
                </VisuallyHidden>
                <VisuallyHidden slot="container-end">
                  <A11ySwiperNavigation />
                </VisuallyHidden>

                {pillars.map((pillar, index) => (
                  <SwiperSlide key={pillar.slug}>
                    <PracticeSetCard
                      type="pillar"
                      pillar={pillar}
                      tag={
                        index === 0 && (
                          <Flex gap="2" align="center">
                            <Image
                              src="/images/learning-journey/bookmark-icon.webp"
                              alt=""
                              boxSize={20}
                              marginRight="-8"
                              zIndex="1"
                            />
                            <Text
                              fontFamily="secondary"
                              textStyle="h3"
                              bg="tertiary.900"
                              py="1"
                              px="3"
                              borderRadius="base"
                            >
                              RECOMMENDED
                            </Text>
                          </Flex>
                        )
                      }
                    />
                  </SwiperSlide>
                ))}
                {pillars.length < count && (
                  <SwiperSlide>
                    <PracticeSetCard type="explorations" />
                  </SwiperSlide>
                )}
              </Swiper>
            </Box>
          );
        }}
      </WaitForQuery>
    </Flex>
  );
}

function A11ySwiperNavigation() {
  const swiper = useSwiper();
  return (
    <Flex justify="space-between">
      <Button onClick={() => swiper.slidePrev()}>Previous</Button>
      <Button onClick={() => swiper.slideNext()}>Next</Button>
    </Flex>
  );
}
