import { BoxProps, Button, Divider, Flex, VStack } from '@chakra-ui/react';

import { TEXT } from '@arena-labs/strive2-ui';
import { StriveApiResponse } from '@strive/api';

export function ShippingInformation({
  onEdit,
  address,
}: {
  onEdit: () => void;
  address: StriveApiResponse<'setWearableOrder'>['address'];
}) {
  return (
    <>
      <Flex direction={'row'} justify={'space-between'} mb={3}>
        <TEXT.P1_SEMIBOLD>Shipping Information</TEXT.P1_SEMIBOLD>
        <Button variant={'link'} color={'primary.500'} onClick={onEdit}>
          <u> Edit</u>
        </Button>
      </Flex>
      <Flex
        direction={'column'}
        layerStyle={'1dp'}
        border={'1px solid'}
        borderColor={'neutral.900'}
        rounded={'md'}
        p={3}
        gap={4}
        mb={10}
      >
        <ShippingInformationEntry
          label={'Full Name'}
          entryLines={[`${address?.full_name}`]}
        />
        <ShippingInformationEntry
          label={'Shipping Email'}
          entryLines={[`${address?.shipping_email}`]}
        />

        <Divider />
        <ShippingInformationEntry
          label={'Shipping Address'}
          entryLines={[
            `${address?.address} ${
              address?.apartment ? address.apartment : ''
            }`,
            `${address?.city}, ${address?.state} ${address?.zip_code}`,
            `${address?.country}`,
          ]}
        />
      </Flex>
    </>
  );
}

export function ShippingInformationEntry({
  label,
  entryLines,
  ...props
}: {
  label: string;
  entryLines: string[];
} & BoxProps) {
  return (
    <VStack spacing={1} align={'left'} {...props}>
      <TEXT.P3 color={'neutral.400'}>{label}</TEXT.P3>
      {entryLines.map((line, idx) => (
        <TEXT.P1_SEMIBOLD key={idx}>{line}</TEXT.P1_SEMIBOLD>
      ))}
    </VStack>
  );
}
