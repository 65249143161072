import { Flex, Modal, ModalBody, ModalContent } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useGradient, useModalDisclosure } from '@arena-labs/strive2-ui';

import { useUpdateLaunchpadChecklist } from '../use-update-launchpad-checklist';
import { CommitToStrive } from './commit-to-strive';
import { UserCommitedView } from './user-commited-view';

CommitmentStatement.useDisclosure = function () {
  return useModalDisclosure('Coaching Expectations');
};

export default function CommitmentStatement({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const gradient = useGradient('blue_white');

  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalContent m={0} bgGradient={'none'}>
        <ModalBody m={0} p={0} bgGradient={'none'}>
          <Flex h={'full'} direction={'row'} bgGradient={gradient}>
            <Swiper allowTouchMove={false} style={{ paddingBottom: '24px' }}>
              <SwiperSlide>
                <CommitToStrive onClose={onClose} />
              </SwiperSlide>
              <SwiperSlide>
                <UserCommitedView
                  isLoading={updateChecklist.isLoading}
                  onClose={() => {
                    updateChecklist.mutate({
                      slug: 'commitment_statement',
                      status: 'completed',
                    });
                  }}
                />
              </SwiperSlide>
            </Swiper>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
