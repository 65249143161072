import { Box, Flex, Grid, Icon } from '@chakra-ui/react';

import { ChevronLeftIcon, TEXT } from '@arena-labs/strive2-ui';

export function LaunchpadActionView({
  onBack,
  hero,
  title,
  textBox,
  footer,
  gradient,
}: {
  title?: string;
  onBack?: () => void;
  hero?: React.ReactNode;
  textBox?: React.ReactNode;
  footer?: React.ReactNode;
  gradient?: string;
}) {
  return (
    <Flex
      w={'full'}
      direction={'column'}
      h={'full'}
      bgGradient={gradient}
      px={'6'}
      pt={'env(safe-area-inset-top)'}
    >
      <Grid
        templateColumns={'40px 1fr 40px'}
        textAlign={'center'}
        alignItems={'center'}
        color={'logo'}
        py={3}
      >
        {onBack && (
          <Flex gridColumn={1} as={'button'} onClick={onBack} py={4}>
            <Icon as={ChevronLeftIcon} boxSize={4} />
          </Flex>
        )}
        {title && (
          <TEXT.H3
            textShadow={
              '0px 5px 5px rgba(0, 0, 0, 0.20), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)'
            }
            w={'full'}
          >
            {title}
          </TEXT.H3>
        )}
      </Grid>

      <Flex
        h={'full'}
        w={'full'}
        direction={'column'}
        gap={10}
        align={'center'}
        textAlign={'center'}
      >
        {hero && (
          <Box my={'auto'} w={'full'}>
            {hero}
          </Box>
        )}
        {textBox && <Box mb={'auto'}>{textBox}</Box>}
        {footer && (
          <Flex
            mt={'auto'}
            w={'full'}
            pb={'calc(env(safe-area-inset-bottom) + 24px)'}
            direction={'column'}
          >
            {footer}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
