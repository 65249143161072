import { Box, Button, VStack } from '@chakra-ui/react';

import { useMenu } from './use-menu';

export function MenuButton() {
  const appMenu = useMenu();
  return (
    <Box position={'relative'}>
      <Button variant={'unstyled'} size={'xs'} onClick={() => appMenu.onOpen()}>
        <VStack w={6} spacing={'5px'}>
          <Box h={'2px'} bg={'logo'} w={'22px'} />
          <Box h={'2px'} bg={'logo'} w={'22px'} />
          <Box h={'2px'} bg={'logo'} w={'22px'} />
        </VStack>
      </Button>
    </Box>
  );
}
