import { Flex, Icon, Modal, ModalBody, ModalContent } from '@chakra-ui/react';

import {
  ChevronLeftIcon,
  TEXT,
  useModalDisclosure,
  UseModalDisclosureOptions,
} from '@arena-labs/strive2-ui';

import { AppBanner } from '../../layout/app-banner';
import EmailCodeEntry from './email-code-entry';

ConfirmEmail.useDisclosure = function (options?: UseModalDisclosureOptions) {
  return useModalDisclosure('Confirm Email', options);
};

export function ConfirmEmail({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalContent m={0} bgGradient={'none'}>
        <ModalBody m={0} p={0} bgGradient={'none'}>
          <Flex
            h={'full'}
            direction={'column'}
            pb={6}
            pt={'env(safe-area-inset-top)'}
          >
            <AppBanner color={'logo'}>
              <Flex
                as={'button'}
                gridColumn={1}
                onClick={() => {
                  onClose();
                }}
              >
                <Icon as={ChevronLeftIcon} />
              </Flex>
              <TEXT.H3>Email Verification</TEXT.H3>
            </AppBanner>
            <EmailCodeEntry onClose={onClose} isOpen={isOpen} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
