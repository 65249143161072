import { useEffect } from 'react';
import { App, BackButtonListenerEvent } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

export function useBackButtonToExitApp() {
  const listenFunction = (event: BackButtonListenerEvent) => {
    App.exitApp();
  };

  useEffect(() => {
    let myPluginEventListener: PluginListenerHandle | null = null;
    (async () => {
      myPluginEventListener = await App.addListener(
        'backButton',
        listenFunction,
      );
    })();

    return () => {
      myPluginEventListener?.remove();
    };
  }, []);
}
