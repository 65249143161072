import { useRouter } from 'next/router';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { groupBy } from 'lodash-es';
import { match, P } from 'ts-pattern';

import {
  ClipboardCheckIcon,
  HandShakeIcon,
  IconProps,
  MailIcon,
  PlayCircleIcon,
  UserProfileIcon,
} from '@arena-labs/strive2-ui';
import { $API, queryClient, StriveApiResponse } from '@strive/api';

import { TypeformModal } from '../home/typeform-modal';
import { ChecklistItem } from './checklist-item';
import CommitmentStatement from './coaching-expectations/commitment-statement';
import { ConfirmEmail } from './confirm-email/confirm-email';
import { LockedChecklistItem } from './locked-checklist-item';
import { SectionHeader } from './section-header';
import {
  LaunchpadChecklistItems,
  LaunchpadWearable,
  TypeformChecklistItems,
} from './types';
import { WearableChecklist } from './wearable-checklist';

export type LaunchpadChecklistProps = {
  launchpad: StriveApiResponse<'getLaunchpad'>;
  reload: () => Promise<void>;
};

export function LaunchpadChecklist({
  launchpad,
  reload,
}: LaunchpadChecklistProps) {
  const { checklist, wearable } = launchpad;
  const groups = groupBy(checklist, 'status');
  return (
    <Flex direction="column" gap="4">
      {groups.active && (
        <ChecklistGroup
          label="Active"
          items={groups.active}
          wearable={wearable}
          onCompleteItem={reload}
        />
      )}

      {groups.completed && (
        <ChecklistGroup
          label="Completed"
          items={groups.completed}
          wearable={wearable}
        />
      )}

      {groups.locked && (
        <ChecklistGroup
          label="Coming Up"
          items={groups.locked}
          wearable={wearable}
        />
      )}
    </Flex>
  );
}

type ChecklistGroupProps = {
  label: string;
  items: NonNullable<LaunchpadChecklistItems>;
  wearable: LaunchpadWearable;
  onCompleteItem?: () => void;
};
function ChecklistGroup({
  label,
  items,
  wearable,
  onCompleteItem,
}: ChecklistGroupProps) {
  return (
    <Flex direction="column" gap="4">
      <SectionHeader label={label} />
      {items.map(
        (item) =>
          item && (
            <LaunchpadChecklistItem
              key={item.slug}
              item={item}
              wearable={wearable}
              onComplete={onCompleteItem}
            />
          ),
      )}
    </Flex>
  );
}

function LaunchpadChecklistItem({
  item,
  wearable,
  onComplete,
}: {
  item: NonNullable<LaunchpadChecklistItems[number]>;
  wearable: LaunchpadWearable;
  onComplete?: () => void;
}) {
  const commitmentStatement = CommitmentStatement.useDisclosure();
  const confirmEmail = ConfirmEmail.useDisclosure();
  const router = useRouter();

  return match(item)
    .with({ status: 'locked' }, () => (
      <LockedChecklistItem label={item.label} />
    ))
    .with(
      { slug: 'setup_wearable', children: P.array(P.any) },
      (setupWearable) =>
        wearable && (
          <WearableChecklist
            list={setupWearable.children}
            wearable={wearable}
            onCompleteItem={onComplete}
            status={setupWearable.status}
          />
        ),
    )
    .with({ slug: 'pfi_questionnaire' }, (form) => (
      <TypeformChecklistItem
        item={form}
        icon={ClipboardCheckIcon}
        onComplete={onComplete}
        duration={10}
        incompleteLabel={'Requirement for StriveWare Shipment'}
      />
    ))
    .with({ slug: 'build_profile_questionnaire' }, (form) => (
      <TypeformChecklistItem
        item={form}
        icon={UserProfileIcon}
        onComplete={onComplete}
        duration={2}
      />
    ))
    .with({ playlist: P.string }, ({ playlist, status, label }) => (
      <ChecklistItem
        icon={PlayCircleIcon}
        status={status}
        label={label}
        onClick={() => router.push(`/launchpad/playlist/${playlist}`)}
      />
    ))
    .with({ slug: 'commitment_statement' }, (details) => (
      <>
        <ChecklistItem
          label={details.label}
          icon={HandShakeIcon}
          status={details.status}
          onClick={() => commitmentStatement.onOpen()}
        />
        <CommitmentStatement {...commitmentStatement} />
      </>
    ))
    .with({ slug: 'email_verification' }, (detail) => (
      <>
        <ChecklistItem
          label={detail.label}
          icon={MailIcon}
          status={detail.status}
          onClick={() => confirmEmail.onOpen()}
        />
        <ConfirmEmail {...confirmEmail} />
      </>
    ))
    .exhaustive();
}

function TypeformChecklistItem({
  item,
  onComplete,
  duration,
  icon,
  incompleteLabel,
}: {
  item: TypeformChecklistItems;
  onComplete?: () => void;
  duration: number;
  icon: IconProps['as'];
  incompleteLabel?: string;
}) {
  const disclosure = useDisclosure();
  const saveTypeformResponse = $API.useSetLaunchpadChecklistItemStatus(
    {},
    {
      onSettled: () => {
        queryClient.invalidateQueries();
        onComplete && onComplete();
      },
    },
  );

  return (
    <>
      <ChecklistItem
        key={item.slug}
        label={item.label}
        status={item.status}
        icon={icon}
        duration={duration}
        onClick={disclosure.onOpen}
        incompleteLabel={incompleteLabel}
      />
      {item.status === 'active' && (
        <TypeformModal
          key={`typeform-${item.slug}`}
          allowClose
          title={item.label}
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          formId={item.typeform_id}
          onSubmitted={({ responseId }) => {
            saveTypeformResponse.mutate({
              slug: item.slug,
              status: 'completed',
            });
          }}
        />
      )}
    </>
  );
}
