import { Box, BoxProps } from '@chakra-ui/react';

export function ImageAndGradientBackground({
  image,
  gradient,
  ...props
}: {
  image: string | undefined;
  gradient?: string;
} & BoxProps) {
  return (
    <Box
      zIndex={-1}
      position={'absolute'}
      top={0}
      left={0}
      h={'full'}
      maxH={'100%'}
      w={'full'}
      bgImage={image}
      bgPosition={'center'}
      backgroundSize={'cover'}
      {...props}
    >
      <Box
        h={'full'}
        w={'full'}
        bgGradient={
          gradient
            ? gradient
            : 'linear-gradient(180deg, rgba(19, 19, 19, 0.3) 0%, rgba(19, 19, 19, 0.8) 52%, #1D1D1D 100%)'
        }
      ></Box>
    </Box>
  );
}
