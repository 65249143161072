import { GeneralTrackingTag } from '@arena-labs/analytics';
import { LearningState, useHomepage } from '@arena-labs/strive2-coaching';

import { GeneralError } from '../components/error/general-error';
import { CoachingHome } from '../components/home/coaching-home';
import { CompleteHome } from '../components/home/complete-home';
import { OnboardingHome } from '../components/home/onboarding-home';
import { LaunchPadHome } from '../components/launchpad/launchpad-home';
import { useResetUpdateIndicator } from '../components/version-update/feature-update-indicator';
import { useBackButtonToExitApp } from '../lib/use-back-button-to-exit-app';

export type GuardRailsProps = {
  children: React.ReactNode;
};

const renderHomepage: Record<LearningState, React.ReactNode> = {
  launchpad: <LaunchPadHome />,
  in_progress: <CoachingHome />,
  onboarding: <OnboardingHome />,
  complete: <CompleteHome />,
};

export default function HomeIndex() {
  const [homepage] = useHomepage();
  const section = homepage?.learning_state;

  useBackButtonToExitApp();
  useResetUpdateIndicator('Home');

  // Display the correct page
  return section ? (
    renderHomepage[section]
  ) : (
    <GeneralError title="Unknown learning state" />
  );
}

HomeIndex.analyticsTags = [GeneralTrackingTag.Home];
