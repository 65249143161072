import { Box, Fade, Flex, Grid, Icon, LinkBox, VStack } from '@chakra-ui/react';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { useAciImage } from '@arena-labs/strive2-data';
import {
  ChevronRightIcon,
  TEXT,
  useGradient,
  useResponsive,
  ZStack,
} from '@arena-labs/strive2-ui';

import { AciCtaWidget } from '../../aci/aci-cta-widget';
import { AppLayout } from '../../layout/app-layout';
import { HomeBanner } from '../../layout/home-banner/home-banner';
import { ImageAndGradientBackground } from '../../practice/practice-detail/image-and-gradient-background';

export function HomeHub({
  practiceSetName,
  willSelectPracticeSet,
  bgImagePath,
  userName,
  practiceName,
}: {
  practiceSetName: string;
  willSelectPracticeSet: boolean;
  bgImagePath: string | undefined | null;
  userName?: string | undefined;
  practiceName?: string | undefined | null;
}) {
  const aciImage = useAciImage();
  const rs = useResponsive();

  const [_, { send }] = useHomepage();
  const gradient = useGradient('primary.10');
  return (
    <AppLayout
      banner={
        <HomeBanner bg={'none'} shadow={'none'} title={practiceSetName} />
      }
    >
      <Fade in style={{ height: '100%' }}>
        <Flex
          justify={'space-evenly'}
          direction={'column'}
          h="full"
          pt={rs({ xs: 2, base: 4 })}
          pb="6"
          px="6"
          _before={{
            // Fade in the background image over the top when loaded
            content: '""',
            position: 'absolute',
            inset: 0,
            zIndex: -1,
            backgroundImage: aciImage.isSuccess
              ? `url(${aciImage.data})`
              : 'none',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: aciImage.isSuccess ? 1 : 0,
            transition: 'opacity 2s ease-in-out',
          }}
          gap={rs({ xs: 4, base: 8 })}
        >
          <TEXT.H1 letterSpacing={'4%'} display={'flex'} flexDir={'column'}>
            <span>Good Morning,</span>
            <span>{userName ?? 'welcome'}.</span>
          </TEXT.H1>

          <VStack>
            <TEXT.P1_SEMIBOLD mr={'auto'}>Coming Up</TEXT.P1_SEMIBOLD>

            <LinkBox
              as={'button'}
              onClick={() => {
                send('Close Landing');
              }}
              w={'full'}
              rounded={'card'}
              overflow={'hidden'}
              bgGradient={gradient}
              zIndex={0}
            >
              <ZStack>
                <Box h={'full'} w={'full'} position={'relative'} zIndex={1}>
                  {bgImagePath && !willSelectPracticeSet && (
                    <ImageAndGradientBackground
                      image={bgImagePath}
                      gradient={gradient}
                    />
                  )}
                </Box>

                <Grid
                  p={4}
                  color={'neutral.400'}
                  templateColumns={'1fr auto'}
                  w={'full'}
                  alignItems={'center'}
                  zIndex={2}
                >
                  <VStack align={'flex-start'} w={'full'}>
                    <TEXT.H3 color={'neutral.white'}>
                      {willSelectPracticeSet
                        ? 'Choose your next set of practices'
                        : `Learn ${practiceName}`}
                    </TEXT.H3>
                    {!willSelectPracticeSet && <TEXT.P2>5 min</TEXT.P2>}
                  </VStack>

                  <Icon as={ChevronRightIcon} boxSize={4} mr={2} />
                </Grid>
              </ZStack>
            </LinkBox>
          </VStack>

          <AciCtaWidget />
        </Flex>
      </Fade>
    </AppLayout>
  );
}
