import { VStack } from '@chakra-ui/react';

import { TEXT } from '@arena-labs/strive2-ui';

import { AciCTACard } from './aci-cta-card';

export function AciCtaWidget() {
  return (
    <VStack gap={1}>
      <TEXT.P1_SEMIBOLD mr={'auto'}>Daily Action</TEXT.P1_SEMIBOLD>
      <AciCTACard />
    </VStack>
  );
}
