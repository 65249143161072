import 'swiper/css';
import 'swiper/css/pagination';

import React from 'react';
import { A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AchievementIncentiveGroup, IncentiveType } from '@strive/api';

import CompetitionInformation from '../../incentives/competition/information/competition-information';
import IncentiveHubIntro from './incentive-hub-intro';
import IncentiveTreasureIntro from './incentive-treasure-chest';
import IncentiveTypes from './incentive-types';

export type OnboardIncentiveProps = {
  incentive: IncentiveType;
  onProceed: () => void;
  onNavigateBack: () => void;
};

export function OnboardIncentive({
  incentive,
  onProceed,
  onNavigateBack,
}: OnboardIncentiveProps) {
  return incentive.type === 'competition' ? (
    <CompetitionInformation
      onProceed={onProceed}
      onNavigateBack={onNavigateBack}
      slug={incentive.slug}
    />
  ) : incentive.type === 'achievements' ? (
    <OnboardingRewardHubInfo
      incentives={incentive.groups}
      onProceed={onProceed}
    />
  ) : undefined;
}

type OnboardingRewardHubInfoProps = {
  incentives: Array<AchievementIncentiveGroup>;
  onProceed: () => void;
};
function OnboardingRewardHubInfo({
  incentives,
  onProceed,
}: OnboardingRewardHubInfoProps) {
  return (
    <Swiper
      style={{ height: '100%', maxHeight: 'var(--height)' }}
      slidesPerView={1}
      modules={[A11y]}
      spaceBetween={50}
    >
      <SwiperSlide>
        <IncentiveHubIntro />
      </SwiperSlide>
      <SwiperSlide>
        <IncentiveTypes incentives={incentives} slideDirection="next" />
      </SwiperSlide>
      <SwiperSlide>
        <IncentiveTreasureIntro primaryAction={onProceed} />
      </SwiperSlide>
    </Swiper>
  );
}
