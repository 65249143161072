import { Button, Center, Image, Spacer, useMediaQuery } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { PageStack, TEXT } from '@arena-labs/strive2-ui';

import { OnboardRewardSlideProps } from './incentive-types';

export default function IncentiveTreasureIntro({
  primaryAction,
}: OnboardRewardSlideProps) {
  const swiper = useSwiper();
  const [isCompact] = useMediaQuery('(max-height: 750px)');

  return (
    <PageStack
      h="full"
      pb="6"
      pt="10"
      spacing="4"
      align="stretch"
      gridArea="content-body"
      overflowY="auto"
      textAlign={'center'}
    >
      <TEXT.H1>Treasure Box</TEXT.H1>

      <Center w={'full'}>
        <Image
          src={'/images/incentives/treasure-outline.gif'}
          alt={'A Pirate-like treasure chest'}
          maxW={220}
        />
      </Center>
      <TEXT.H1 fontSize={isCompact ? 'h2' : 'h1'}>
        Collect your Treasure Box at the end of the program!
      </TEXT.H1>
      <TEXT.H2 fontSize={isCompact ? 'h3' : 'h2'} px={4}>
        Rewards claimed throughout Strive will be saved in your Treasure Box.
      </TEXT.H2>

      <Spacer />
      <Button
        variant="primary"
        onClick={primaryAction ? primaryAction : () => swiper.slideNext()}
      >
        Begin Your Strive Journey!
      </Button>
    </PageStack>
  );
}
