import { Button, Flex, Icon } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { ChevronLeftIcon, TEXT } from '@arena-labs/strive2-ui';
import { $API, Address } from '@strive/api';

import { AppBanner } from '../../../layout/app-banner';
import { AddressForm } from '../../address-form';

export function SizingKitOrderForm({
  setDeliveryAddress,
}: {
  setDeliveryAddress: (address: Address) => void;
}) {
  const swiper = useSwiper();
  const { data } = $API.useGetUserProfile();
  return (
    <Flex
      direction={'column'}
      maxH={'100vh'}
      h={'full'}
      pt={'env(safe-area-inset-top)'}
      position={'relative'}
    >
      <AppBanner shadow={'none'} color={'logo'}>
        <Button
          onClick={() => swiper.slidePrev()}
          variant={'unstyled'}
          size={'xs'}
        >
          <Icon as={ChevronLeftIcon} boxSize={4} />
        </Button>
        <TEXT.H3 gridColumn={2}>Shipping Address</TEXT.H3>
      </AppBanner>
      <Flex direction={'column'} px={6} pt={12} overflowY={'scroll'}>
        <TEXT.H2>Receive a Sizing Kit </TEXT.H2>
        <TEXT.P1 color={'neutral.400'} mb={'10'}>
          Please provide your shipping address. We&apos;ll send you a sizing
          kit!
        </TEXT.P1>

        <AddressForm
          defaultValues={
            data
              ? {
                  full_name: `${data.first_name} ${data.last_name}`,
                  shipping_email: data.email,
                }
              : {}
          }
          onSuccess={(address: Address) => {
            address && setDeliveryAddress(address);
            swiper.slideNext();
          }}
        />
      </Flex>
    </Flex>
  );
}
