import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  SlideFade,
  VisuallyHidden,
} from '@chakra-ui/react';

import { PracticesTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { isMediaPlayed, PillarPractice } from '@arena-labs/shared-models';
import { Elevation, PenIcon, TEXT } from '@arena-labs/strive2-ui';
import { queryClient } from '@strive/api';

import { LogPracticeCard } from '../practice/log-practice-card';
import { useSkipPractice } from '../practice/use-log-practice';

type LogCurrentPracticeProps = {
  practice: PillarPractice | undefined;
};

export default function LogCurrentPractice({
  practice,
}: LogCurrentPracticeProps) {
  const skipPractice = useSkipPractice({ retry: 3 });
  const analytics = useAnalytics();

  if (!practice || !isMediaPlayed(practice.intro)) {
    return null;
  }

  return (
    <Flex direction="column" align="stretch" gap={4} w="full" h="full">
      <HStack>
        <Icon as={PenIcon} boxSize={6} color="icon.tertiary" />
        <TEXT.H3>Complete Your Practice</TEXT.H3>
      </HStack>
      <Box color={'neutral.400'}>
        <TEXT.P2 lineHeight={'taller'}>
          Try the new Practice once after each Lesson.
        </TEXT.P2>
        <HStack>
          <TEXT.P2>Check the</TEXT.P2>
          <Box
            h="18px"
            w="18px"
            borderRadius={'sm'}
            border="2px solid"
            borderColor={'primary.700'}
          >
            <VisuallyHidden>checkbox</VisuallyHidden>
          </Box>
          <TEXT.P2>to move on </TEXT.P2>
        </HStack>
      </Box>

      <LogPracticeCard
        onSuccess={() => {
          queryClient.invalidateQueries();
        }}
        practice={practice}
        isChecked={practice.completed_today}
      />

      <Box mt="auto">
        <SlideFade in offsetY="50px" transition={{ enter: { delay: 2 } }}>
          <Elevation level="2dp" borderRadius="card" p="4">
            <TEXT.H3 as="p" textAlign="center" mb="4">
              Not a good time?
            </TEXT.H3>
            <Button
              variant="primary"
              w="full"
              isLoading={skipPractice.isLoading}
              onClick={() => {
                analytics.logEvent(PracticesTrackingEvent.PracticeSkipped);
                skipPractice.mutate({ practice: practice.slug });
              }}
            >
              Skip the practice
            </Button>
          </Elevation>
        </SlideFade>
      </Box>
    </Flex>
  );
}
