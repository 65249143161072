import { $API } from '@strive/api';

/**
 * Get the next ${limit} pillars which have not been started
 * And also the total number of available pillars (could be larger than $limit)
 */
export function useAvailablePracticeSets(limit?: number) {
  return $API.useGetPillars(
    {},
    {
      select: (pillars) => {
        const notStarted = pillars.filter(
          (pillar) => pillar.state === 'not_started',
        );
        const next = notStarted.slice(0, limit);
        return [next, notStarted.length] as const;
      },
    },
  );
}
