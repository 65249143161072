import { useEffect } from 'react';
import NextLink from 'next/link';
import {
  Button,
  chakra,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Link,
} from '@chakra-ui/react';
import confetti from 'canvas-confetti';

import {
  MotionBox,
  TEXT,
  useGradient,
  useModalDisclosure,
} from '@arena-labs/strive2-ui';

PillarCompleteModal.useDisclosure = function () {
  return useModalDisclosure('Pillar Complete');
};

export default function PillarCompleteModal({
  isOpen,
  onClose,
  explorationsStartsTomorrow,
  pillarIconPath,
  pillarTitle,
}: {
  isOpen: boolean;
  onClose: () => void;
  explorationsStartsTomorrow?: boolean;
  pillarIconPath?: string;
  pillarTitle: string | undefined;
}) {
  useEffect(() => {
    let confettiTimer = setTimeout(() => {
      if (isOpen) {
        confetti({
          shapes: ['square', 'circle'],
          angle: 70,
          startVelocity: 60,
          particleCount: 30,
          spread: 40,
          origin: {
            x: 0.0,
            y: 0.9,
          },
          disableForReducedMotion: true,
          zIndex: 99999,
        });

        confetti({
          shapes: ['square', 'circle'],
          angle: 110,
          startVelocity: 60,
          particleCount: 30,
          spread: 40,
          origin: {
            x: 1.1,
            y: 0.9,
          },
          disableForReducedMotion: true,
          zIndex: 99999,
        });
      }
    }, 2000);

    return () => {
      clearTimeout(confettiTimer);
    };
  }, [isOpen]);

  const gradient = useGradient('blue_orange');

  return (
    <Drawer isOpen={isOpen} size="full" placement="bottom" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody
          h="full"
          pt="env(safe-area-inset-top)"
          px="0"
          bgGradient={!explorationsStartsTomorrow ? gradient : 'none'}
        >
          <Flex
            direction={'column'}
            h={'full'}
            textAlign="center"
            pt="70"
            pb="4"
            px="6"
            overflow={'hidden'}
          >
            <MotionBox
              direction={'column'}
              h={'full'}
              animate={{
                translateY: ['200px', '0px'],
              }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                delay: 4,
              }}
            >
              <Flex direction="column" gap="6">
                {pillarIconPath && !explorationsStartsTomorrow ? (
                  <MotionBox
                    w={'88px'}
                    mx={'auto'}
                    animate={{
                      opacity: [1, 0, 0, 0],
                      height: [100, 100, 0, 0],
                    }}
                    transition={{
                      duration: 2,
                      ease: 'easeInOut',
                      delay: 4,
                    }}
                  >
                    <Image h={'auto'} w={'full'} src={pillarIconPath} alt="" />
                  </MotionBox>
                ) : null}
                <TEXT.H1 color="white">
                  {explorationsStartsTomorrow
                    ? 'Strive Foundations Completed!'
                    : `You've Completed ${pillarTitle ?? 'this practice set'}!`}
                </TEXT.H1>
                <TEXT.H3 color="neutral.400">
                  {explorationsStartsTomorrow
                    ? `You've Mastered Strive Foundations with a Wealth of New
                  Practices!`
                    : `How have these practices worked for you? Let your coach know when
                and how have you practiced them!`}
                </TEXT.H3>
              </Flex>
              <MotionBox
                direction={'column'}
                animate={{
                  translateY: ['200px', '0px'],
                  opacity: ['0%', '100%'],
                }}
                transition={{
                  duration: 2,
                  ease: 'easeInOut',
                  delay: 4.5,
                }}
              >
                <Flex direction="column" gap="4" mt={28}>
                  <TEXT.H1 color="white">
                    {explorationsStartsTomorrow ? 'Next Up!' : `More Aha's!`}
                  </TEXT.H1>

                  {explorationsStartsTomorrow ? (
                    <TEXT.H3 color="neutral.400">
                      You are entering a self-guided journey called the
                      <chakra.span color={'secondary.400'}>
                        {' '}
                        Strive Explorations
                      </chakra.span>
                      . Get Excited!
                    </TEXT.H3>
                  ) : (
                    <TEXT.H3 color="neutral.400">
                      {' '}
                      Tomorrow you can choose the next Practice Set that&apos;s
                      right for you! Keep up the great work!
                    </TEXT.H3>
                  )}
                </Flex>
              </MotionBox>
            </MotionBox>

            <MotionBox
              animate={{
                opacity: ['0%', '100%'],
              }}
              transition={{
                duration: 2,
                ease: 'easeInOut',
                delay: 5.5,
              }}
            >
              <Link as={NextLink} href={'/'}>
                <Button
                  w={'full'}
                  mt={'auto'}
                  variant={'primary'}
                  onClick={onClose}
                >
                  {' '}
                  Home
                </Button>
              </Link>
            </MotionBox>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
