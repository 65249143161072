import {
  Button,
  Flex,
  HStack,
  Image,
  RadioProps,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { RadioButton, TEXT, useGradient } from '@arena-labs/strive2-ui';

import { LaunchpadActionView } from '../launchpad-action-view';

export function SelectStrivewareRingStyle({
  selection,
  onSelect,
}: {
  selection: string | undefined;
  onSelect: (selection: string) => void;
}) {
  const swiper = useSwiper();

  const gradient = useGradient('blue_white');
  const selectedButtonGradient = useGradient('secondary.light');

  const { getRadioProps } = useRadioGroup({
    name: 'ringFinish',
    onChange: (nextValue: string) => {
      onSelect(nextValue);
    },
  });
  const buttonNames = ['Matte Black', 'Rose Gold'];

  return (
    <LaunchpadActionView
      gradient={gradient}
      onBack={() => swiper.slidePrev()}
      hero={
        <>
          <TEXT.H2 mb={'24'} textAlign={'left'} color={'neutral.white'}>
            Select The Color{' '}
          </TEXT.H2>
          <VStack w={'full'} spacing={6}>
            {buttonNames.map((buttonName, index) => (
              <div key={`${buttonName}${index}`} style={{ width: '100%' }}>
                <RadioButton
                  radio={{
                    p: 0,
                    shadow: 'none',
                    _checked: {},
                  }}
                  variant={'unstyled'}
                  {...(getRadioProps({ value: buttonName }) as RadioProps)}
                >
                  <HStack
                    color={'neutral.black'}
                    gap={6}
                    bgGradient={
                      selection === buttonName
                        ? selectedButtonGradient
                        : 'linear-gradient(neutral.300, neutral.300)'
                    }
                    border={'3px solid'}
                    borderColor={
                      selection === buttonName ? 'primary.700' : 'neutral.300'
                    }
                    rounded={'card'}
                    py={4}
                    px={8}
                  >
                    <Image
                      w={'120px'}
                      alt={`${buttonName} Striveware Ring`}
                      src={
                        buttonName === 'Rose Gold'
                          ? './images/striveware/rose-gold-ring-no-light-left.webp'
                          : './images/striveware/black-ring-green-light-left.webp'
                      }
                    />
                    <TEXT.P2>{buttonName}</TEXT.P2>
                  </HStack>
                </RadioButton>
              </div>
            ))}
          </VStack>
        </>
      }
      footer={
        <Flex direction={'column'} gap={6} w={'full'} align={'center'}>
          <Button
            disabled={!selection}
            variant={'primary'}
            w={'full'}
            onClick={() => swiper.slideNext()}
          >
            Next
          </Button>
        </Flex>
      }
    />
  );
}
