import { Grid, Text } from '@chakra-ui/react';

import { Icon, LockIcon } from '@arena-labs/strive2-ui';

export function LockedChecklistItem({ label }: { label: string }) {
  return (
    <Grid
      templateColumns="auto 1fr"
      alignItems="center"
      gap="2"
      color="neutral.700"
    >
      <Icon as={LockIcon} />
      <Text textStyle="p1_semibold">{label}</Text>
    </Grid>
  );
}
