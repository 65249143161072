import { Button, Image } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { AlertBox, useGradient } from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { LaunchpadActionText } from '../../launchpad-action-text';
import { LaunchpadActionView } from '../../launchpad-action-view';
import { useUpdateLaunchpadChecklist } from '../../use-update-launchpad-checklist';

export function SizingKitOrderRecieved({
  onProceed,
  wearableProvider,
}: {
  onProceed: () => void;
  wearableProvider: WearableProviders;
}) {
  const gradient = useGradient('blue_white');
  const swiper = useSwiper();

  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onProceed });

  return (
    <LaunchpadActionView
      gradient={gradient}
      onBack={() => swiper.slidePrev()}
      hero={
        <Image
          mb={10}
          src={
            wearableProvider === 'oura'
              ? './images/launchpad/ring-sizing-kit.webp'
              : './images/launchpad/delivery-drone.webp'
          }
          alt={'Oura Ring Sizing Kit'}
          w={'240px'}
          mx={'auto'}
        />
      }
      textBox={
        <LaunchpadActionText
          boldText={'Your Sizing Kit is on the way!'}
          bodyText={'It usually takes 5-7 days to be delivered.'}
        />
      }
      footer={
        <>
          {updateChecklist.isError ? (
            <AlertBox
              textAlign={'left'}
              mb={4}
              status="error"
              title={'Oops!'}
              description={
                'We could not update your Launchpad Checklist at this time. Try Again!'
              }
            />
          ) : null}
          <Button
            isLoading={updateChecklist.isLoading}
            variant="primary"
            w={'full'}
            onClick={() => {
              updateChecklist.mutate({
                slug: 'wearable_order',
                status: 'completed',
              });
            }}
          >
            Home
          </Button>
        </>
      }
    />
  );
}
