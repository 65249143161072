import { Button, Image } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { LaunchpadActionText } from '../launchpad-action-text';
import { LaunchpadActionView } from '../launchpad-action-view';

export function CommitToStrive({ onClose }: { onClose: () => void }) {
  const swiper = useSwiper();

  return (
    <LaunchpadActionView
      onBack={onClose}
      hero={
        <Image
          mt={6}
          px={6}
          w={'full'}
          src={'./images/launchpad/friends-window.webp'}
          alt={'Meaning Conversation With a Coach'}
        />
      }
      textBox={
        <LaunchpadActionText
          boldText="Ready to commit to your journey with us?"
          bodyText={`At Arena, we partner with those who are ready to put in the effort. Are you committed to this journey with us?`}
        />
      }
      footer={
        <Button
          variant={'primary'}
          w={'full'}
          onClick={() => swiper.slideNext()}
        >
          I commit!
        </Button>
      }
    />
  );
}
