import { useMutation } from '@tanstack/react-query';

import { $API, apiClient } from '@strive/api';

export type UseChoosePracticeSetOptions = {
  onSuccess?: (data: unknown) => unknown;
  onError?: (error: unknown) => unknown;
  onSettled?: (data: unknown, error: unknown) => unknown;
};

export type UseChoosePracticeSetVariables =
  | {
      type: 'pillar';
      pillarSlug: string;
    }
  | {
      type: 'explorations';
    };

export function useChoosePracticeSet({
  onSuccess,
  onError,
  onSettled,
}: UseChoosePracticeSetOptions = {}) {
  const homepageQuery = $API.useGetHomepage();
  return useMutation({
    mutationFn: async (data: UseChoosePracticeSetVariables) => {
      if (data.type === 'explorations') {
        await apiClient.setLearningState({ learning_state: 'complete' });
      } else {
        await apiClient.selectPillar({
          selected_pillar_slug: data.pillarSlug,
        });
      }

      // Wait for the homepage to refetch
      await homepageQuery.refetch();
    },
    onSuccess,
    onSettled,
    onError,
  });
}
