import { chakra, Grid, Text } from '@chakra-ui/react';

import { Icon, IconProps } from '@arena-labs/strive2-ui';

type ChecklistItemProps = {
  label: string;
  icon: IconProps['as'];
  status: 'active' | 'completed' | 'skipped' | 'locked';
  onClick?: () => void;
  duration?: number;
  incompleteLabel?: string;
};

export function ChecklistItem({
  label,
  icon,
  status,
  duration,
  onClick,
  incompleteLabel,
}: ChecklistItemProps) {
  const Wrapper = status === 'active' ? chakra.button : chakra.div;
  const wrapperProps = status === 'active' ? { onClick } : {};

  return (
    <Wrapper
      layerStyle="1dp"
      border="1px solid"
      borderColor="neutral.900"
      textAlign="left"
      borderRadius="base"
      py="6"
      px="4"
      {...wrapperProps}
    >
      <Grid templateColumns="auto 1fr" alignItems="center" gap="2">
        <Icon as={icon} color="secondary.400" />
        <Text textStyle="p1_semibold" color="white">
          {label}
        </Text>
        {status === 'active' && duration ? (
          <Text textStyle="p3" color="neutral.400" gridColumn="2">
            {duration} min
          </Text>
        ) : null}
        {status === 'completed' ? (
          <Text textStyle="p3" color="success.300" gridColumn="2">
            Completed
          </Text>
        ) : (
          <Text textStyle="p3" color="error.300" gridColumn="2">
            {incompleteLabel ?? 'Not Completed'}
          </Text>
        )}
      </Grid>
    </Wrapper>
  );
}
