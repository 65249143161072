import { captureException } from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';

import { $API } from '@strive/api';

type UseUpdateLaunchpadChecklistProps = {
  onSuccess?: () => unknown;
  onError?: (
    error: unknown,
    { status, slug }: { status: string; slug: string },
  ) => unknown;
};

export function useUpdateLaunchpadChecklist({
  onSuccess,
  onError,
}: UseUpdateLaunchpadChecklistProps) {
  const queryClient = useQueryClient();
  return $API.useSetLaunchpadChecklistItemStatus(
    {},
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        onSuccess?.();
      },
      onError: (error, { status, slug }) => {
        captureException(error, {
          data: `Error updating the Launchpad Checklist "${slug}" to "${status}"`,
        });
        onError?.(error, { status, slug });
      },
    },
  );
}
